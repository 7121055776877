import React from "react"
import "../../components/animations/scroll.scss"
import TheaterVideo from "@components/Theater/TheaterVideo"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

function StaticHeading(props) {
  const {
    post: { svgHeading, svgSubheading, sizzleYoutube },
    language
  } = props
  return (
    <div
      style={{
        textAlign: "left"
      }}
      className="static-heading"
    >
      <div
        className="above-text-container"
        style={{ opacity: "1", transition: "unset" }}
      >
        {svgHeading.map((heading, i) => (
          <React.Fragment key={i}>
            <span role="heading" className="curtain-heading h3-style">
              {heading}
            </span>
            <br />
          </React.Fragment>
        ))}

        <MarkdownViewer
          className="above-text__subheader"
          markdown={svgSubheading}
        />
      </div>

      {sizzleYoutube && (
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${sizzleYoutube}`}
          controls={true}
          playing={true}
          onlyButton={true}
          sizzleButton={true}
          buttonClass="sizzle-button-under contained"
          compId={"sizzleTheaterButton"}
          language={language}
        />
      )}
    </div>
  )
}

export default StaticHeading
