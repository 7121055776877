import React from "react"
import classNames from "classnames"

import ImageMeta from "../../components/ImageMeta"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import TheaterVideo from "@components/Theater/TheaterVideo"

const WhyChooseOMS = ({
  heading,
  blurb,
  image,
  youtube,
  hasThisSection,
  colorBack,
}) => {
  if (!hasThisSection) return null
  const mainClasses = classNames("p-section", "why-choose-oms", {
    "color-back": colorBack,
  })
  return (
    <div className={mainClasses}>
      <div className="columns is-vcentered flex-row-reverse">
        <div className="column is-3"></div>
        <div className="column" data-aos="fade-left" data-aos-duration="1200">
          <h2 className="h3-style mt-0">{heading}</h2>
          <MarkdownViewer markdown={blurb} />

          <div className="has-text-centered-mobile">
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
              playing={true}
              onlyButton={true}
              buttonClass="contained"
              language={"en"}
            />
          </div>
        </div>

        <div className="column is-2"></div>

        <div className="column mt-2-mobile">
          <ImageMeta
            data-aos="fade-right"
            data-aos-duration="1200"
            cloudName="nuvolum"
            publicId={image}
            responsive
          />
        </div>
        <div className="column is-3"></div>
      </div>
    </div>
  )
}

export default WhyChooseOMS
